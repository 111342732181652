import StandardPageModel from 'Models/Pages/StandardPage/StandardPageModel.interface';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import { BLOCK_TYPES } from 'Shared/Constants/common';
import { usePageData } from '../../../context/page-data.context';

function StandardPage() {
  const { hero, contentArea, inEditMode } = usePageData<StandardPageModel>();
  const isContactPage =
    contentArea?.length > 0 &&
    contentArea.some((item) => item.blockType === BLOCK_TYPES.CONTACT_US_BLOCK);

  const width = isContactPage ? 'narrow' : 'content';

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer
        {...applyEditModeAttr(inEditMode && 'MainContent')}
        width={width}
      >
        {Array.isArray(contentArea) && <ContentArea items={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default StandardPage;
